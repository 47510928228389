<style>
	.iconfont.iconfont-bg {
		float: right;
		font-size: 64px;
		transform: rotate(30deg);
		position: relative;
		right: -20px;
		bottom: -40px;
		color: rgba(255, 255, 255, 0.5);
	}
</style>
<template>
	<div>
		<!-- <el-row :gutter="10">
			<el-col :span="16"> -->
		<!-- 添加编辑表单 -->
		<el-dialog :title="'添加/编辑'+title" :visible.sync="is_show">
			<el-form ref="writeForm" :model="writeForm" :rules="writeFormRules" :inline="true" label-width="150px" size="small">
				<slot name="writeForm" :writeForm="writeForm" />
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitWriteForm()" size="small">提交</el-button>
				<el-button @click="resetWriteForm" size="small">重置</el-button>
			</div>
		</el-dialog>
		<!-- 展示列表 -->
		<el-card shadow="always">
			<el-form ref="searchForm" :model="searchForm" label-width="auto" size="mini" inline>
				<slot name="searchForm" :searchForm="searchForm" :show_more_search="show_more_search" />
				<el-link type="primary" @click="onSearchMore">{{show_more_title}}</el-link>
				<el-row style="text-align:right;margin-top:10px">
					<el-button type="primary" size="mini" @click="onSearch" icon="el-icon-search">查询</el-button>
					<el-button type="primary" size="mini" @click="onSearchClear" icon="el-icon-delete" plain>重置</el-button>
				</el-row>
			</el-form>
		</el-card>
		<slot name="tableOpt" />
		<el-card style="margin-top:10px" v-if="role!='普通管理员'">
			<el-link style="margin-right:10px" icon="el-icon-check" type="primary" @click="handleSelectAll">
				<template v-if="select_all">全选</template>
				<template v-else>取消全选</template>
			</el-link>
			<el-link style="margin-right:10px" icon="el-icon-delete" type="primary" @click="onDeleteAll()">批量删除 </el-link>
			<!-- <el-link style="margin-right:10px" icon="el-icon-plus" type="primary" @click="showWriteForm(0)"> 添加项目</el-link> -->
			<el-link style="margin-right:10px" icon="el-icon-download" type="primary" @click="onExport">导出结果</el-link>
			<el-link style="margin-right:10px" icon="el-icon-document" type="primary" @click="onExportAll">下载全部数据</el-link>
			<el-upload action="http://zhny.rayoai.com/api/admin/Xls/set_db_xls" :show-file-list="false" :data="{table_name:table_name}" :on-success="show_set_db_xls" style="display: inline;">
				<el-link type="primary" icon="el-icon-upload2">批量导入</el-link>
			</el-upload>
		</el-card>
		<el-card shadow="always" style="margin-top:10px">
			<el-table ref="tableList" :show-header="table_show_header" :data="tableData" @selection-change="handleSelectOne" :header-cell-style="{ textAlign: 'center',background: '#fafafa',fontWeight: 500,color:'#333'}" stripe>
				<el-table-column type="selection" width="60" align="center" />
				<slot name="table" />
			</el-table>
			<p style="background:#fff;text-align:center;margin:0;padding:20px 0">
				<el-pagination @current-change="handlePage" @size-change="handleSizeChange" :page-sizes="[10,20,50,100,500]" :current-page="page" :page-size="pagesize" :total="total" :page-count="pagecount" layout="sizes,prev, pager, next,total" />
			</p>
		</el-card>
		<!-- </el-col>
			<el-col :span="8">
				<slot name="sider" />
			</el-col>
		</el-row> -->
	</div>
</template>
<script>
	import request from "../plugins/axios.js";
	export default {
		name: "madmin",
		props: {
			//标题
			title: {
				type: String,
				default: "项目",
			},
			//表名
			table_name: {
				type: String,
				default: "",
			},
			table_show_header: {
				type: Boolean,
				default: false,
			},
			//列表请求的附加参数
			list_post: {
				type: Object,
				default: () => {},
			},
			//表单的验证规则
			writeFormRules: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				role: window.localStorage.getItem("role"),
				//列表
				searchForm: {}, //搜索表单数据
				show_more_search: false, //是否隐藏高级搜索条件
				show_more_title: "高级检索 >>", //高级搜索标题
				tableData: [], //列表数据
				multipleSelection: [], //复选框选中项
				select_all: true, //点击按钮后的全选状态：true-点击按钮全选、false-点击按钮取消全选
				//列表分页
				page: 1, //当前页码
				pagesize: 10, //每页显示条数
				total: 0, //总条数
				pagecount: 0, //总页数
				//添加编辑表单
				writeForm: {}, //表单数据
				is_show: false, //是否显示弹出框
				detail_action: "", //表单加载数据请求url
				write_action: "", //表单提交请求url
			};
		},
		created() {
			this.get_list(1);
		},
		methods: {
			/** 搜索表单组件*/
			//搜索按钮
			onSearch() {
				this.get_list(1);
			},
			onSearchMore() {
				//是否显示高级搜索
				this.show_more_search = !this.show_more_search;
				if (this.show_more_search) {
					this.show_more_title = "简单检索 <<";
				} else {
					this.show_more_title = "高级检索 >>";
					this.onSearchClear();
					this.onSearch();
				}
			},
			//重置搜索表单按钮
			onSearchClear() {
				this.$refs["searchForm"].resetFields();
				this.get_list(1);
			},
			/** 列表*/
			//切换单个复选框的状态
			handleSelectOne(val) {
				this.multipleSelection = val;
			},
			//切换所有复选框的选中状态
			handleSelectAll() {
				if (this.select_all) {
					this.$refs.tableList.toggleAllSelection();
				} else {
					this.$refs.tableList.clearSelection();
				}
				this.select_all = !this.select_all;
			},
			//获取列表数据
			get_list(page) {
				let post = {};
				if (window.localStorage.getItem("role") == "企业管理员") {
					post = {
						company_id: window.localStorage.getItem("company_id"),
					};
				}
				Object.assign(post, this.list_post);
				Object.assign(post, this.searchForm);
				Object.assign(post, {
					page: page,
					pagesize: this.pagesize,
				});
				request({
					url: "/admin/" + this.table_name + "/list",
					data: post,
				}).then(
					(ret) => {
						if (ret.code == 1) {
							this.tableData = ret.data.list;
							this.page = ret.data.page;
							this.pagesize = ret.data.pagesize;
							this.total = ret.data.total_num;
							this.pagecount = ret.data.total_page;
						}
					},
					(err) => {
						this.$notify.error({
							title: "请求异常",
							message: err,
						});
					}
				);
			},
			//处理翻页
			handlePage(page) {
				this.get_list(page);
			},
			//处理改变分页大小
			handleSizeChange(pagesize) {
				this.pagesize = pagesize;
				this.get_list(1);
			},
			//删除信息
			onDelete(id) {
				let url = "/admin/" + this.table_name + "/del/" + id;
				request({
					url: url,
					data: {},
				}).then(
					(ret) => {
						if (ret.code == 1) {
							this.$notify({
								title: "删除成功",
								message: "数据已更新",
								type: "success",
							});
							this.get_list(this.page);
						} else {
							this.$notify.error({
								title: "删除失败",
								message: ret.msg,
							});
						}
					},
					(err) => {
						this.$notify.error({
							title: "请求异常",
							message: err,
						});
					}
				);
			},
			//批量删除删除
			onDeleteAll() {
				let url = "/admin/" + this.table_name + "/del_all/";

				if (this.multipleSelection.length > 0) {
					let ids = [];
					for (let i = 0; i < this.multipleSelection.length; i++) {
						ids[i] = this.multipleSelection[i].id;
					}
					request({
						url: url,
						data: {
							ids,
						},
					}).then(
						(ret) => {
							if (ret.code == 1) {
								this.$notify({
									title: "删除成功",
									message: "数据已更新",
									type: "success",
								});
								this.get_list(this.page);
							} else {
								this.$notify.error({
									title: "删除失败",
									message: ret.msg,
								});
							}
						},
						(err) => {
							this.$notify.error({
								title: "请求异常",
								message: err,
							});
						}
					);
					this.get_list(1);
				} else {
					this.$notify.error({
						title: "请求异常",
						message: "请先勾选待删除项目",
					});
				}
			},
			//获取详情数据
			get_info() {
				request({
					url: this.detail_action,
					data: {},
				}).then(
					(ret) => {
						if (ret.code == 1) {
							// this.$notify({
							//   title: '操作成功',
							//   message: msg,
							//   type: 'success'
							// });
							this.writeForm = ret.data;
						} else {
							this.$notify.error({
								title: "获取数据失败",
								message: ret.msg,
							});
						}
					},
					(err) => {
						this.$notify.error({
							title: "请求异常",
							message: err,
						});
					}
				);
			},
			//表单加载
			showWriteForm(id) {
				this.detail_action =
					id == 0 ? "" : "/admin/" + this.table_name + "/detail/" + id;
				this.write_action = "/admin/" + this.table_name + "/edit/" + id;
				this.is_show = true;
				if (id == 0) {
					this.writeForm.id = 0;
					// this.writeForm.create_time = "";
					// this.writeForm.update_time = "";
				}
				// 重置表单
				this.resetWriteForm();
				// 获取数据
				if (this.detail_action != "") {
					this.get_info();
				}
			},
			//表单提交
			submitWriteForm() {
				let post = {};
				Object.assign(post, this.writeForm);
				request({
					url: this.write_action,
					data: post,
				}).then(
					(ret) => {
						if (ret.code == 1) {
							this.$notify({
								title: "操作成功",
								message: "数据已更新",
								type: "success",
							});

							this.resetWriteForm();
							this.is_show = false;
							this.get_list(1);
						} else {
							this.$notify.error({
								title: "提交失败",
								message: ret.msg,
							});
						}
					},
					(err) => {
						this.$notify.error({
							title: "请求异常",
							message: err,
						});
					}
				);
			},
			//表单重置
			resetWriteForm() {
				if (this.$refs["writeForm"] !== undefined) {
					let id = this.writeForm.id;
					let create_time = this.writeForm.create_time;
					let update_time = this.writeForm.update_time;
					this.$refs["writeForm"].resetFields();
					this.writeForm = {};
					this.writeForm.id = id;
					this.writeForm.create_time = create_time;
					this.writeForm.update_time = update_time;
				}
			},
			//导出文档
			onExport() {
				let post = {};
				Object.assign(post, this.list_post);
				Object.assign(post, this.searchForm);
				request({
					url: "/admin/" + this.table_name + "/export",
					data: post,
				}).then(
					(ret) => {
						if (ret.code == 1) {
							window.open(ret.data.save_file, "_blank").location;
						} else {
							this.$notify.error({
								title: "导出失败",
								message: ret.msg,
							});
						}
					},
					(err) => {
						this.$notify.error({
							title: "请求异常",
							message: err,
						});
					}
				);
			},
			//下载全部数据
			onExportAll() {
				request({
					url: "/admin/Xls/get_db_xls",
					data: {
						table_name: this.table_name,
					},
				}).then(
					(ret) => {
						if (ret.code == 1) {
							window.open(ret.data.save_file, "_blank").location;
						} else {
							this.$notify.error({
								title: "下载失败",
								message: ret.msg,
							});
						}
					},
					(err) => {}
				);
			},
			//处理导入结果
			show_set_db_xls(ret) {
				if (ret.code == 1) {
					this.$notify({
						title: "操作成功",
						message: "导入成功",
						type: "success",
					});
					this.onSearch();
				} else {
					this.$notify.error({
						title: "操作失败",
						message: ret.msg,
					});
				}
			},
		},
	};
</script>